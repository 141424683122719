<template>
  <van-loading color="#1989fa" size="32" vertical>加载中...</van-loading>
</template>
<script>
export default {
  name: "Loading",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped>
.van-loading {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
