<template>
  <div class="orderTimeLine" v-if="stepList.length">
    <van-steps
      direction="vertical"
      :active="
        stepList[stepList.length - 1].bizType == 4 ? stepList.length - 1 : 6
      "
      active-icon="success"
      :inactive-icon="
        stepList[stepList.length - 1].bizType == 5 ? 'close' : 'clock-o'
      "
    >
      <van-step v-for="item in stepList" :key="item.id">
        <h3>{{ item.content }}</h3>
        <p>{{ item.createBy }}</p>
        <p>{{ item.createTime }}</p>
      </van-step>
    </van-steps>
  </div>
</template>
<script>
import { getOrderTimeLine } from "../service/api/index";
export default {
  name: "OrderTimeLine",
  props: {
    id: String,
  },
  data() {
    return { stepList: [] };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getOrderTimeLine({ orderId: this.id }).then((res) => {
        this.stepList = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.orderTimeLine {
  margin-bottom: 10px;
}
</style>
